import useGetTabs from '@/Components/Dashboard/Profile/Contents/useGetTabs'
import Form from '@/Components/Form/Form'
import Headings from '@/Components/Dashboard/Profile/Headings'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { TabsLock } from '@/Components/ControlsLocked/TabsLock'
const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
  `}
`

const TITLE = 'edit_profil.contents.title'
const SUBTITLE = 'edit_profil.contents.subtitle'

const ManageContents = () => {
  const tabs = useGetTabs()
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser

  const [selectedTab, setSelectedTab] = useState(tabs[0].title)
  const { onSubmit } = useEditCurrentUser()

  if (!user) {
    return null
  }

  return (
    <Form onSubmit={onSubmit}>
      <Headings title={TITLE} subtitle={SUBTITLE} />
      <Wrapper>
        <TabsLock />
      </Wrapper>
    </Form>
  )
}
export default ManageContents
