const admin_whitelabel = {
  admin_space: 'Espace Admin',
  manage_your_users:
    'Retrouvez votre espace administrateur et la gestion de vos utilisateurs.',
  users: 'Utilisateurs',
  custom_domain: 'Domaine personnalisé',
  custom_email: 'Email personnalisé',
  annexes: 'Annexes',
  your_users: 'Vos utilisateurs',
  custom_email_with_smtp: 'Email personnalisé avec SMTP',
  customize_the_domain:
    "Personnalisez le domaine de l'email qui sera utilisé avec vos utilisateurs avec SMTP",
  custom_email_with_gmail: 'Email personnalisé avec GMAIL',
  customize_the_gmail:
    "Personnalisez le domaine de l'email qui sera utilisé avec vos utilisateurs avec GMAIL",
  host: 'Hôte',
  port: 'Port',
  user: 'Utilisateur',
  pass: 'Mot de passe',
  link_signup: "Lien d'inscription",
  copy_the_link:
    "Copier le lien ci-dessous pour inviter des utilisateurs à s'inscrire sur votre plateforme",
  legal_links: 'Liens légaux',
  modify_the_links:
    'Vous pouvez modifier vos liens de conditions générales et politique de confidentialité ici',
  general_conditions: 'Conditions générales',
  privacy_policy: 'Politique de confidentialité',
  intercom_token: 'Token Intercom',
  connect_your_own_livechat:
    'Vous pouvez ajouter votre token intercom afin de connecter votre propre livechat',
  collaborator_details: 'Détails du collaborateur',
  no_username: "Pas d'username",
  member_since: 'Membre depuis le',
}

export default admin_whitelabel
