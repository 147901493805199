import { usePutCurrentUserMutation } from '@/Services/modules/users'
import { updateCurrentMember } from '@/Store/Member'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import useCurrentUser from './useCurrentUser'
import { useSelectAllMembers } from './useSelectAllMembers'

const informationFields = [
  'firstName',
  'lastName',
  'job',
  'businessName',
  'description',
  'hashtags',
  'businessMode',
  'photo',
  'logo',
  'banner',
]
const appearanceFields = [
  'background-color',
  'shape-avatar',
  'btnShape',
  'hide-information',
  'replicate-profile',
]
const LOCKED_FIELDS = {
  user: [...informationFields, ...appearanceFields],
  qrcode: ['patternQrCode', 'backgroundQrCode', 'eyesQrCode', 'logo'],
  businessCard: ['template', 'information', 'logo', 'color'],
  content: ['contact', 'social', 'links', 'gallery', 'shop'],
}

const useCurrentMember = () => {
  const [searchParams] = useSearchParams()
  const isMultiMemberEdition = searchParams.get('multiple') || false
  const { currentMember, checkedAllMemberUid } = useSelector(
    state => state.member,
  )
  const { members } = useSelectAllMembers()
  const { currentUser } = useCurrentUser()
  const [putCurrentUser] = usePutCurrentUserMutation()
  const dispatch = useDispatch()
  const setCurrentMember = async body => {
    try {
      if (isMultiMemberEdition) {
        const memberSelected = members.filter(member =>
          checkedAllMemberUid.includes(member.uid),
        )

        await Promise.all(
          memberSelected.map(async member => {
            const isAdmin = member.uid === currentUser.uid
            const newBody = isAdmin
              ? { ...body, lockedFields: undefined }
              : body.qrcode
                ? {
                    ...body,
                    qrcode: {
                      ...body.qrcode,
                      id: member.qrcodeId,
                    },
                  }
                : { ...body }
            await putCurrentUser({
              body: newBody,
              userId: member.uid,
            })
          }),
        )
        return
      }

      const response = await putCurrentUser({
        body,
        userId: currentMember.uid,
      })
      if (response.error) {
        console.warn(response.error)
        throw new Error(response.error.data.error)
      }
      dispatch(updateCurrentMember(body))
    } catch (err) {
      throw new Error(err)
    }
  }

  const isEveryFieldLocked = lockedFields => {
    const keys = Object.keys(LOCKED_FIELDS)
    const allFieldsLocked = keys.map(key => {
      if (LOCKED_FIELDS[key]?.length !== lockedFields[key]?.length) {
        return false
      }
      return true
    })
    return allFieldsLocked.every(val => val === true)
  }
  const isAllTabFieldLocked = (
    compareTabField = 'informationFields',
    currentFields = [],
  ) => {
    return compareTabField.every((val, i) => currentFields.includes(val))
  }

  return {
    currentMember,
    setCurrentMember: currentMember ? setCurrentMember : null,
    isEveryFieldLocked,
    isAllTabFieldLocked,
    LOCKED_FIELDS,
    informationFields,
    appearanceFields,
    isMultiMemberEdition,
  }
}

export default useCurrentMember
