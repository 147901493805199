import getCosmeticsByUId from './getCosmeticsByUId'
import { api } from '@/Services/api'

export const cosmeticsApi = api.injectEndpoints({
  endpoints: build => ({
    getCosmeticsByUId: getCosmeticsByUId(build),
  }),
  overrideExisting: true,
})

export const { useGetCosmeticsByUIdQuery } = cosmeticsApi
