import React, { useState } from 'react'
import { Tabs } from '@/Components/Controls/Tabs'
import ProfileAddContact from './ProfileAddContact'
import ProfileBanner from './ProfileBanner'
import { useProfileContext } from './Contexts/ProfileContext'
import { ProfileDescription } from './ProfileDescription'
import { ProfileIdentity } from './ProfileIdentity'
import { useGetTabs } from './Utils/useGetTabs'
import styled, { css } from 'styled-components'
import { ProfileReport } from './ProfileReport'
import { DownloadApplicationBanner } from './DownloadApplicationBanner'
import { useEffect } from 'react'
import { isMobileOrDesktop } from '@/Utils/isMobileOrDesktop'
import { useAddAnalyticsMutation } from '@/Services/modules/users'
import clsx from 'clsx'
const StyledPhoneProfile = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
  `}
`
const ProfileWrapper = styled.div`
  width: ${({ isPreview }) => (isPreview ? '100%' : '520px')};
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  zoom: ${({ isPreview }) => (isPreview ? '82%' : '100%')};
`

const Profile = ({
  ip = 'ip-not-found',
  country = 'country-not-found',
  isPreview = false,
}) => {
  const { profile } = useProfileContext()
  const tabs = useGetTabs(profile)
  const [selectedTab, setSelectedTab] = useState(tabs[0]?.title)
  const [addAnalytics] = useAddAnalyticsMutation()
  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(tabs[0]?.title)
    }
  }, [tabs, selectedTab])

  const handleAddClick = async ({ contentId, type }) => {
    try {
      await addAnalytics({
        userUid: profile.uid,
        body: {
          ip,
          country,
          device: isMobileOrDesktop() ? 'Mobile' : 'Desktop',
          date: Date.now(),
          referrer: '',
          contentId,
          type,
        },
        collection: 'analytics_clicks',
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <StyledPhoneProfile className="flex justify-center w-full profile">
      <ProfileWrapper
        className="min-h-screen flex flex-col py-6"
        isPreview={isPreview}
      >
        <DownloadApplicationBanner isPreview={isPreview} />
        <ProfileBanner />
        <ProfileIdentity />
        <ProfileAddContact />
        <ProfileDescription />
        {tabs.length > 1 ? (
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        ) : null}
        {tabs.map((tab, index) => {
          if (tab.title === 'Shop') {
            const visible = selectedTab === 'Shop'
            return (
              <div
                className={clsx(
                  !visible && 'opacity-0 absolute pointer-events-none w-0 h-0',
                )}
              >
                <tab.component key={index} />
              </div>
            )
          }
          if (!tab.component) return null

          return (
            selectedTab === tab.title && (
              <tab.component key={index} onAddClick={handleAddClick} />
            )
          )
        })}
        <ProfileReport />
      </ProfileWrapper>
    </StyledPhoneProfile>
  )
}

export default Profile
