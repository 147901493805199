const teams_page = {
  you: 'You',
  invite_members_title: 'Invite members by email',
  invite_members_subtitle:
    'Add members of your team by email and we will send them an invitation to join your team. Roles can be modified later.',
  invite_members_form: {
    email: 'Email',
    firstname: 'First Name',
    job: 'Job',
    add_member: 'Add a member',
    send_invitations_directly: 'Send invitations directly',
    total_price: 'Total',
  },
  see_profile: 'See profile',
  invite_members_button: 'Invite',
  know_more_about_roles_1: 'Learn more about',
  know_more_about_roles_2: 'team member roles',
  this_member: 'this member',
  number_products: '{{productsLength}} product{{plurial}}',
  edit: 'Edit',
  manage: 'Manage',
  details: 'Details',
  duplicate: 'Duplicate',
  the_email_has_been: 'The email has been sent to your member.',
  invite_member: 'Invite the member',
  send_invitations_directly: 'Send invitations directly by email',
  invitation_sent: 'Invitation sent',
  edit_selection: 'Edit selection',
  search_for_a_member: 'Search for a member by name ...',
  confirm_field_lock: 'Confirm {{lockdown}} of fields',
  lockdown_title: 'lockdown',
  lockdown_subtitle: 'lock',
  unlock_title: 'unlocking',
  unlock_subtitle: 'unlock',
  are_you_sure:
    'Are you sure you want to {{lockdown}} all fields for this member "{{firstName}} {{lastName}}" ? This action is irreversible.',
  select_columns_to_include: 'Select the columns to include.',
  we_found: 'We found',
  columns_to_use: ' columns to use',
  statistics: 'Statistics',
  view_team_statistics: 'View the main statistics of your teams',
  team_space: 'Team Space',
  team_member_information: 'Find all the information about your team members',
  loading_member_data: 'Loading data for all members...',
  active_members: 'Active Members',
  member: 'members',
  inactive_members: 'Inactive Members',
  pending_invited_members: 'Find all invited members awaiting activation',
  import_csv: 'Import CSV',
  invite_members: 'Invite members',
  invitation: 'Invitation',
  invite_members_by_csv: 'Invite members via CSV',
  invite_members_by_csv_subtitle:
    'Add a list of your team members with a CSV file and we will send them an invitation. Roles can be modified later.',
  not_set: 'Not set',
}

export default teams_page
