import { initializeApp, getApps } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDqQqK3T4i_xWxV9b9vRI5nNwUMjd5ggtE',
  authDomain: 'clink-d33fc.firebaseapp.com',
  databaseURL: 'https://clink-d33fc.firebaseio.com',
  projectId: 'clink-d33fc',
  storageBucket: 'clink-d33fc.firebasestorage.app',
  messagingSenderId: '118191226',
  appId: '1:118191226:web:51dd1e5e9704cf3fac37c3',
  persistence: true,
}
let app = null
if (getApps().length === 0) {
  app = initializeApp(firebaseConfig)
}
export default app
