import Text from '@/Components/Texts/Text'
import { useGetWhitelabel, useTheme } from '@/Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Headings = ({
  title,
  subtitle,
  action = null,
  renderComponent = () => null,
}) => {
  const whitelabel = useGetWhitelabel()
  const { t } = useTranslation()
  const { theme } = useTheme()
  return (
    <div className="flex gap-2 mb-10 items-center tablet:mb-5">
      {action}
      <div className="flex flex-col w-full">
        {title && <Text className="text-base">{t(title)}</Text>}
        {subtitle && (
          <Text color={theme.colors.grey2} className="text-sm font-normal mt-1">
            {t(subtitle, {
              whitelabelName: whitelabel?.whiteLabelName || 'YourLifeCard',
            })}
          </Text>
        )}
        {renderComponent()}
      </div>
    </div>
  )
}

export default Headings
