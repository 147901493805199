const parametre = {
  title: 'Configuraciones',
  title_bloc1: 'PRODUCTOS',
  title_bloc2: 'GENERAL',
  title_bloc3: 'SEGURIDAD',
  title_bloc4: 'APOYO',
  community_title: 'COMMUNIDAD',
  title_bloc6: 'ACERCA DE',
  produit_ylc: {
    activer_produit: {
      title: 'Activar un producto',
      select: 'Seleccione un método de activación',
      description:
        'Elige entre estas opciones cuál quieres usar para activar tu producto.',
      subtitle: 'Escanea el QRCode en el empaque del producto.',
      detect_product: 'Detectar mi producto',
      subtitle2: 'Acerque el producto a la parte posterior de su teléfono',
      productDetected: 'Producto detectado',
      productActivate: 'Presionar para activar',
      manuellement: {
        title: 'Agregar manualmente mi producto',
        subtitle: 'Introduzca el código escrito en el embalaje del producto.',
        modal: {
          title: 'Nuevo producto',
          subtitle:
            'Agrega el código que se encuentra en el empaque del producto',
          input: 'Introduce el código del producto',
          btn: 'Validar',
        },
      },
    },
    my_products: {
      insight: 'Mis productos',
      title: 'Encuentra todos tus productos',
      subtitle: 'Activa, desactiva y modifica todos tus productos',
      modal_delete: {
        title: 'Eliminación de productos',
        subtitle: '¿Está seguro de que desea eliminar este producto? ?',
        btn: {
          delete_btn: 'Borrar',
          cancel_btn: 'Anular',
        },
        bottom_btn: {
          btn_add: 'Agregar',
          btn_get: 'Obtener',
        },
      },
    },
    get_product: {
      title: 'Adquiere uno de nuestros productos',
    },
    unlock_ylc_team: {
      title: 'Descubra YLC Team',
      subtitle: 'Controle la narrativa de su marca con YLC Team',
      description:
        'YLC Team es un panel de administración que permite a las empresas gestionar las tarjetas de presentación de YLC Team para sus empleados.',
      possibilities: 'Como usuario de YLC Team, puedes:',
      possibility_1: 'Crear, editar y eliminar tarjetas para tus empleados',
      possibility_2:
        'Actualizar todas las tarjetas de tu empresa cuando cambien los datos de la empresa (por ejemplo, dirección de la empresa, logotipo o enlaces de marketing)',
      possibility_3:
        'Hacer que las tarjetas no sean editables para ciertos usuarios',
      possibility_4:
        'Recopilar y exportar los detalles de contacto de las personas que reciben las tarjetas de presentación de tus empleados',
      access: 'Además, todos tus empleados tienen acceso a YLC Team!',
      bottom_btn: 'Descubrir',
    },
  },

  services: {
    title: 'SERVICIOS',
    ylc_Travel: {
      option_title: 'Descubra YLC Travel',
      title:
        'Ahorra en grande en tus viajes con nuestras tarifas secretas y descuentos increíbles!',
      description:
        'Descubre nuestra puerta trasera a los precios al por mayor y descuentos ocultos que los minoristas en línea no quieren que conozcas. Únete a nosotros para ahorrar miles de dólares cada año en tus viajes!',
      title_possibilities: 'Como usuario de YLC Travel, puedes:',
      possibility_1:
        'Disfruta de hasta un 70% de descuento en tus dos primeras noches en más de dos millones de hoteles en todo el mundo gracias a nuestras ofertas exclusivas.',
      possibility_2:
        'Explora el mundo por un tercio del precio habitual gracias a nuestras ofertas excepcionales y tarifas exclusivas.',
      possibility_3:
        'No dejes que los altos precios de viaje te detengan. Con nuestra suscripción de solo 99 dólares al año, el mundo está al alcance de tu mano.',
    },
    ylc_Card: {
      option_title: 'Descubra YLC Card',
      title:
        'Crea y Comparte tu Tarjeta de Visita Digital Definitiva con Nuestra Aplicación Única!',
      description:
        'Olvídate de las obsoletas tarjetas de visita en papel. Nuestra revolucionaria aplicación es todo lo que necesitas para crear, personalizar y compartir tu tarjeta de visita digital. Explora infinitas posibilidades de personalización, comparte tus datos de contacto fácilmente, sigue tus vistas y clics, todo en una experiencia digital amigable.',
      title_possibilities: 'Como usuario de YLC Card, puedes:',
      possibility_1: 'Crea una tarjeta única infinitamente.',
      possibility_2: 'Comparte tus datos de contacto con un clic.',
      possibility_3:
        'Sigue las estadísticas en tiempo real de tus vistas y clics.',
      possibility_4: 'Amigable con el medio ambiente, sin necesidad de papel.',
      possibility_5:
        'Accesibilidad máxima, tu tarjeta disponible en cualquier lugar.',
    },
    ylc_Wallet: {
      option_title: 'Descubra YLC Wallet',
      title:
        'Invierte en el futuro con nuestros compañeros de rendimiento excepcional y contribuye a un impacto positivo!',
      description:
        'Descubre nuestra colección única de compañeros que ofrece rendimientos asombrosos, de hasta un 300% al año. Al invertir en nuestros compañeros, no solo obtienes ganancias impresionantes, sino que también contribuyes a la reforestación. Por cada tarjeta compañero creada, plantamos un árbol, lo que significa que tus beneficios contribuyen a un futuro más verde y próspero.',
      title_possibilities: 'Como usuario de YLC Wallet, puedes:',
      possibility_1:
        'Nuestros compañeros ofrecen un rendimiento potencial excepcional, de hasta un 300% al año.',
      possibility_2:
        'Sigue fácilmente tus beneficios generados por nuestros compañeros.',
      possibility_3:
        'Cada tarjeta compañero creada equivale a la plantación de un árbol, para un mundo más verde.',
    },
    mall: {
      option_title: 'Descubra Mall',
      title:
        'Obtén un 70% de descuento en una selección de productos y disfruta de precios preferenciales para los miembros de App!',
      description:
        'Descubre una oferta excepcional: obtén un 70% de descuento en una selección de productos de alta calidad. En App valoramos a nuestros usuarios, por eso te ofrecemos precios preferenciales si eres miembro de App o has acumulado puntos. Aprovecha estos beneficios exclusivos y ahorra en una variedad de productos con descuento.',
      title_possibilities: 'Como usuario de Mall, puedes:',
      possibility_1:
        'Descuento del 70%: ahorra significativamente en una variedad de productos de calidad.',
      possibility_2:
        'Precios preferenciales para los miembros de App: como miembro, disfruta de tarifas aún más ventajosas.',
      possibility_3:
        'Utiliza tus puntos para ahorrar: acumula puntos y reduce el costo de tus compras.',
      possibility_4:
        'Amplia selección de productos: elige entre una amplia variedad de productos con descuento para satisfacer todas tus necesidades.',
      tab_mall: {
        title: '¡El centro comercial está llegando!',
        subtitle:
          '¡Experimenta las compras de lujo a un costo asequible! El centro comercial ofrece las mejores marcas de lujo a precios increíblemente reducidos, con entrega gratuita*.',
        advantages: {
          title: 'Productos de Marca y Diseñadores',
          subadvantages: 'Disfruta de las mejores marcas a precios ventajosos.',
          title2: 'La Experiencia de Compras de Lujo',
          subadvantages2: 'Descubre más de 100,000 productos exclusivos.',
          title3: 'Envío Gratuito a Más de 100 Países',
          subadvantages3:
            'Disfruta de envío gratuito para pedidos superiores a 150 €.',
        },
        notify_me: 'Notifícame cuando se lance',
        reminder_enabled: 'Recordatorio activado',
      },
    },
  },
  modal_menu: {
    settings: 'Configuración y preferencias',
    bookmarks: 'Ver mis favoritos',
    share_QR_code: 'Compartir mi perfil',
    scan_QR_code: 'Escanear mi código QR',
  },
  general: {
    personal_info: {
      title: 'Información personal',
      btn: 'Registro',
      inputs: {
        my_link: 'Mi enlace personalizado',
        email: 'Dirección de correo electrónico',
        number: 'Número de teléfono',
      },
    },

    link_preference: {
      title: 'Mostrar preferencias',
      btn: 'Registro',
      subtitle: 'Elija su preferencia de visualización de enlace de perfil',
      list_shap: {
        title: 'como una lista',
        subtitle: 'Icono, título y descripción visibles',
      },
      icon_shap: {
        title: 'Como un icono',
        subtitle: 'Solo icono de enlace',
      },
    },

    seo: {
      top: {
        title: 'Vista previa de metadatos',
        sentence: 'Mi seo personalizado',
      },
      bottom: {
        title: ' Edita tu información',
        email: 'Título',
        description: 'Metadescripción',
      },
    },

    statistique: {
      title: 'Mis estadísticas',
      title_tabs1: 'Generales',
      title_tabs2: 'Vistas',
      title_tabs3: 'Vistas únicas',
      title_tabs4: 'Clics',
      title_tabs5: 'CTR',
      title_tabs6: 'Vista del dispositivo',
      title_tabs7: 'Vista por país',
      title_tabs8: 'Principales redes sociales',
      title_tabs9: 'Principales enlaces',
      title_tabs10: 'Principales contactos',
      top: {
        title: 'Título',
        clicks: 'Clics',
        graph: 'Grafico',
        show_graph: 'Mostrar en gráfico',
      },
      deleted_content: 'Contenido eliminado',
      week_card_text: 'Esta semana',
      no_data: 'No hay datos disponibles',
    },

    password: {
      insight: 'Contraseña',
      title: 'Contraseña actual',
      description: 'Introduce tu contraseña actual para poder restablecerla',
      input: 'Contraseña actual',
      btn: 'Restablecer mi contraseña',
      notif: {
        email:
          'La dirección de correo electrónico ya está en uso por otra cuenta.',
        error: 'Ocurrió un error. Inténtalo de nuevo.',
        psw: 'Debe especificar una contraseña',
        psw2: 'La contraseña debe contener al menos 6 caracteres de al menos una letra y un número',
        psw3: 'Las contraseñas no coinciden',
        psw4: 'La contraseña o el correo electrónico que ingresó es incorrecto. Por favor, inténtelo de nuevo.',
        psw5: 'No se encontró ningún usuario con este identificador. Es posible que la cuenta haya sido eliminada.',
      },
    },

    newpassword: {
      title: 'Nueva contraseña',
      subtitle:
        'Su nueva contraseña debe ser diferente a la que utilizó anteriormente.',
      inputs: {
        new_psw: 'Nueva contraseña',
        confirm_new_psw: 'Confirmar nueva contraseña',
      },
      btn: 'Cambiar mi contraseña',
    },

    notification_settings: {
      title: 'Notificaciones',
    },

    darkmode: {
      title: 'Modo oscuro',
      enabled: 'Activado',
      disabled: 'Desactivado',
      device: 'Modo del dispositivo',
      theme_changed: 'El tema cambió exitosamente!',
      activate_dark_mode:
        'Active el modo oscuro para reducir la fatiga ocular. Usa el botón de abajo para alternar entre los temas claro y oscuro.',
    },

    languages: {
      insight: 'Idioma',
      title: 'Selección de idioma',
      subtitle: 'El idioma seleccionado se aplicará a toda la aplicación',
      choice: {
        fr: 'Francés',
        en: 'Inglés',
        es: 'Español',
      },
      save: 'Para salvaguardar',
    },
  },

  plan: {
    pro_abo: {
      title: 'Suscripción profesional',
      subtitle:
        'Acceda a nuestras suscripciones Pro directamente en nuestro sitio web',
    },
  },

  share: {
    copy: 'Copiar',
    contact_us: {
      title: 'Contáctenos',
      subtitle: 'Estamos para ayudarte, no dudes en contactarnos',
      type: {
        email: 'Por email',
        direct_chat: {
          title: 'Contacto Chat en vivo',
          subtitle: 'Estamos listos para responderte',
        },
      },
    },

    share_friend: {
      title: 'Compartir con un amigo',
    },
    referral: {
      title: 'Recomendar a un amigo',
      module_title:
        'Gana 10 semillas, 10 gotas y 10 monedas gracias a tus amigos.',
      reward_text:
        '¡Gana 10 semillas, 10 gotas y 10 monedas por cada amigo invitado!',
      reward_subtitle:
        'Obtén recompensas para ti y tu amigo por cada recomendación validada.',
      referred_friends: 'Ver mis <bold>{{friends}}</bold> amigos patrocinados',
      no_referred_friends: 'No amigo patrocinados',
      share_referral_link: 'Comparte tu enlace de patrocinio',
      become_ambassador: 'Convertirse en embajador?',
      invite_friends: 'Invitar amigos',
      share_message:
        'Bienvenido a App, la aplicación para tu identidad digital.',
      share_link: '🌳 Aquí está mi código de patrocinador:',
      app_link:
        'Para descargar la aplicación haga clic en el siguiente enlace: https://budly.link/dl-app?w=ylc ',
      sponsored_friends: 'Amigos patrocinados',
      copy: 'Copiar',
      referred_friend_all: 'Tus referidos',
      search: 'Buscar una persona',
      referred: 'Patrocinado',
      valid: 'Validado',
      pending: 'En curso',
      referrer: 'Mi referente',
      finish_your_quests:
        'Completa tus misiones de bienvenida para validar la referencia',
      drops_rewarded: 'Gotas de agua obtenidas',
      seeds_rewarded: 'Semillas obtenidas',
      coins_rewarded: 'Monedas obtenidas',
      empty_results: {
        no_sponsored_person: 'No se encontró persona patrocinada',
        it_looks_like_you: 'Parece que aún no has patrocinado a esta persona.',
      },
    },
    explain_referral: {
      screen_title: 'Ganar recompensas',
      title: {
        main_text:
          'Gana <color>{{1}}</color>, <color>{{2}}</color> y <color>{{3}}</color> por cada amigo invitado!',
        1: '10 semillas',
        2: '10 gota',
        3: '10 monedas',
      },
      subtitle:
        'Obtén recompensas y hazle ganar a tu amigo por cada recomendación validada.',
      subscribe_title: 'Regístrate con tu enlace',
      subscribe_text: 'Su amigo debe registrarse con su código de referencia',
      finish_quest_title: 'Completa sus misiones de bienvenida',
      finish_quest_text:
        'Su amigo debe completar sus misiones de bienvenida para validar la referencia',
      collect_reward_title: 'Conseguir tus recompensas',
      collect_reward_text:
        'Una vez completadas las misiones de bienvenida, usted y su amigo recibirán 10 semillas, 10 gotas y 10 monedas gratis.',
    },
    review: {
      title: 'Déjanos una reseña',
    },

    faq: {
      title: 'Preguntas Frecuentes',
    },

    deconnect: {
      title: 'Desconectarse',
    },

    delete_acc: {
      delete: 'Eliminar mi cuenta',
    },
  },
  about: {
    privacy: 'Política de privacidad',
    conditions: 'Términos y condiciones',
    version: 'Versión',
  },
}

export default parametre
