import React, { useState } from 'react'
import FormSubmitButton from '../Form/FormSubmitButton'
import useGetTabs from '../Dashboard/Profile/Contents/useGetTabs'
import { Tabs } from '../Controls/Tabs'
import { LockedFieldSwitch } from '../LockedFieldSwitch'
import { useEditCurrentUser } from '../Dashboard/Contexts/EditProfileContext'
import { useLockedField } from '@/Hooks/useLockedField'
import { useSearchParams } from 'react-router-dom'

export const TabsLock = () => {
  const [searchParams] = useSearchParams()
  const isEditionMember =
    searchParams.has('member_user_id') || searchParams.has('multiple')
  const { editCurrentUser } = useEditCurrentUser()
  const tabs = useGetTabs()
  const [selectedTab, setSelectedTab] = useState(tabs[0].title)
  const { onLocked } = useLockedField()
  return (
    <>
      <div className="">
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="min-w-20 pl-0"
        />
        <div className="flex flex-row">
          {tabs.map(tab => {
            const isLocked =
              editCurrentUser.lockedFields?.content?.includes(tab.name) || false
            return (
              <LockedFieldSwitch
                className="w-full justify-center items-center"
                isLocked={isLocked}
                onChange={e =>
                  onLocked({
                    e: e,
                    type: 'content',
                    fieldName: tab.name,
                  })
                }
              />
            )
          })}
        </div>
      </div>
      {tabs.map(tab => {
        const Component = tab.component
        const isLocked =
          editCurrentUser.lockedFields?.content?.includes(tab.name) &&
          !isEditionMember

        if (tab.title === selectedTab) {
          return (
            <BlurContent isLocked={isLocked}>
              <Component key={tab.name} />
              {tab.submit && <FormSubmitButton className="w-full" />}
            </BlurContent>
          )
        }
        return null
      })}
    </>
  )
}

const BlurContent = ({ children, isLocked }) => {
  if (!isLocked) {
    return children
  }
  return <div className="blur-[2px] pointer-events-none">{children}</div>
}
