import { Config } from '@/Config'
import { storage } from '@/Firebase/Storage'
import { isBase64 } from '@/Utils/isBase64'
import { randomId } from '@/Utils/randomId'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const useUploadImage = () => {
  const uploadLegacyImage = async ({ uri, id, path }) => {
    try {
      const response = await fetch(uri)
      const blob = await response.blob()
      const imageRef = ref(storage, path)
      const snapshot = await uploadBytes(imageRef, blob)
      const src = await getDownloadURL(snapshot.ref)
      return { id, src }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadBase64Image = async ({ uri, id, path }) => {
    try {
      const reference = storage.ref(path)
      const task = reference.putString(uri, 'data_url')
      await task
      const src = await reference.getDownloadURL()
      return { id, src }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadRegularImage = async ({ uri, id, path }) => {
    try {
      const baseUrl = Config.API_URL
      const response = await fetch(`${baseUrl}/upload/webp-from-uri`, {
        method: 'POST',
        body: JSON.stringify({ path, uri }),
      })
      const data = await response.json()
      if (data.error) {
        throw new Error(data.error)
      }
      return { id, src: data }
    } catch (error) {
      console.error(error)
    }
  }

  const uploadImage = async (uri, legacy) => {
    const id = randomId(10)
    const path = 'images/' + id
    if (legacy) {
      return uploadLegacyImage({ uri, id, path })
    }

    if (isBase64(uri)) {
      return uploadBase64Image({ uri, id, path })
    }

    return uploadRegularImage({ uri, id, path })
  }

  return {
    uploadImage,
  }
}

export default useUploadImage
