import {
  LineArrowBottom,
  LineLock,
  LineUnlock,
} from '@/Assets/icons/components'
import { useShareUrl } from '../Panels/PanelShareProfile/ShareUrlContext'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import { useTranslation } from 'react-i18next'
import HeaderProfile from '../HeaderProfile'
import { useTheme } from '@/Hooks'
import ModalProfileMode from '../Modals/ModalProfileMode'

const WrapperIconAndBusiness = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${theme.fontSize.m};
  `}
`
const PublicMode = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
    font-weight: 400;
  `}
`

const ShareBar = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { currentUser } = useSelector(state => state.user)
  const { url, setUrl } = useShareUrl()
  const publicMode = useMemo(() => {
    const slug = url.split('/').pop()
    return slug === currentUser.customUrl
  }, [url, currentUser.customUrl])

  return (
    <>
      <ModalProfileMode setProfileUrl={setUrl}>
        {setIsOpen => (
          <HeaderProfile icon={LineArrowBottom} onClick={() => setIsOpen(true)}>
            <WrapperIconAndBusiness>
              <div className="flex flex-row items-center">
                {publicMode ? (
                  <LineUnlock
                    width={18}
                    height={17}
                    viewBox="0 2 24 19"
                    color={theme.colors.grey2}
                    className="mb-0.5"
                  />
                ) : (
                  <LineLock
                    width={18}
                    height={17}
                    viewBox="0 2 24 19"
                    color={theme.colors.grey2}
                    className="mb-0.5"
                  />
                )}
                <PublicMode className="ml-1 text-xs">
                  {publicMode
                    ? t('share_profil.type_profil.public_profil')
                    : t('share_profil.type_profil.private_profil')}
                </PublicMode>
              </div>
            </WrapperIconAndBusiness>
          </HeaderProfile>
        )}
      </ModalProfileMode>
    </>
  )
}

export default ShareBar
