const teams_page = {
  you: 'Tú',
  invite_members_title: 'Invitar miembros por email',
  invite_members_subtitle:
    'Agrega miembros de tu equipo por email y les enviaremos una invitación para unirse a tu equipo. Los roles se pueden modificar más adelante.',
  invite_members_form: {
    email: 'Email',
    firstname: 'Nombre',
    job: 'Función',
    add_member: 'Agregar un miembro',
    send_invitations_directly: 'Enviar invitaciones directamente',
    total_price: 'Total',
  },
  see_profile: 'Ver perfil',
  invite_members_button: 'Invitar',
  know_more_about_roles_1: 'Saber más sobre',
  know_more_about_roles_2: 'los roles de los miembros del equipo',
  this_member: 'este miembro',
  number_products: '{{productsLength}} producto{{plurial}}',
  edit: 'Editar',
  manage: 'Gestionar',
  details: 'Detalles',
  duplicate: 'Duplicar',
  the_email_has_been: 'El email ha sido enviado a tu miembro.',
  invite_member: 'Invitar al miembro',
  send_invitations_directly: 'Enviar directamente las invitaciones por correo',
  invitation_sent: 'Invitación enviada',
  edit_selection: 'Editar la selección',
  search_for_a_member: 'Buscar un miembro por su nombre ...',
  confirm_field_lock: 'Confirmar el {{lockdown}} de campos',
  lockdown_title: 'bloqueo',
  lockdown_subtitle: 'cerrar',
  unlock_title: 'desbloqueo',
  unlock_subtitle: 'desbloquear',
  are_you_sure:
    '¿Está seguro de querer {{lockdown}} todos los campos para este miembro "{{firstName}} {{lastName}}" ? Esta acción es irreversible.',
  select_columns_to_include: 'Seleccione las columnas a incluir.',
  we_found: 'Hemos encontrado',
  columns_to_use: ' columnas a utilizar.',
  statistics: 'Estadísticas',
  view_team_statistics: 'Encuentra las principales estadísticas de tus equipos',
  team_space: 'Espacio de Equipo',
  team_member_information:
    'Encuentra toda la información sobre los miembros de tu equipo',
  loading_member_data: 'Cargando datos de todos los miembros...',
  active_members: 'Miembros Activos',
  member: 'miembros',
  inactive_members: 'Miembros inactivos',
  pending_invited_members:
    'Encuentra a todos los miembros invitados pendientes de activación',
  import_csv: 'Importar CSV',
  invite_members: 'Invitar miembros',
  invitation: 'Invitación',
  invite_members_by_csv: 'Invitar miembros por CSV',
  invite_members_by_csv_subtitle:
    'Agrega una lista de miembros de tu equipo con un archivo CSV y les enviaremos una invitación. Los roles se pueden modificar más adelante.',
  not_set: 'No establecido',
}

export default teams_page
