import { useGetWhitelabelBySlugAndDomainQuery } from '@/Services/modules/whitelabel'
import { useSelector } from 'react-redux'

export default function useGetWhitelabel() {
  const { slug } = useSelector(state => state.whitelabel)
  const whitelabelSlug = slug
  const {
    data: whitelabel = {
      whiteLabelName: 'YourLifeCard',
      logoSmall: require('@/Assets/images/logo.png'),
      color2: '#58637D',
    },
    isLoading,
  } = useGetWhitelabelBySlugAndDomainQuery({
    slug: whitelabelSlug,
    domain: window.location.host,
  })

  if (isLoading) return null

  return whitelabel
}
