import { QRCode } from './QRCode'
import { useGetWhitelabel } from '@/Hooks'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { TouchableOpacity } from '../Buttons/TouchableOpacity'
import Text from '../Texts/Text'
import useLocalStorage from '@/Hooks/useLocalStorage'
import { toast } from 'react-toastify'
import { useLazyGetQrCodeImageByIdQuery } from '@/Services/modules/qrcode'
import { Select } from '../Controls/Select'
import QrcodeBloc from './ShareBlocs/QrcodeBloc'

const Card = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.grey1};
    border-radius: ${theme.borderRadius.l};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.space.l} ${theme.space.m};
    position: relative;
    flex-direction: column;
  `}
`
const TitleAndIcon = styled(TouchableOpacity)`
  ${({ theme }) => css`
    padding: ${theme.space.xs} ${theme.space.s} ${theme.space.xs}
      ${theme.space.m};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.space.std};
    background: ${theme.colors.white};
    border-radius: ${theme.borderRadius.l};
  `}
`
const StyledText = styled(Text)`
  ${({ theme }) => css`
    font-weight: 500;
    font-size: ${theme.fontSize.s};
    color: ${theme.colors.black};
  `}
`

const CardQrCode = () => {
  const timer = useRef()
  const qrcodeRef = useRef()
  const [shareProfileMode, setShareProfileMode] = useState('PROFILE')
  const { currentUser } = useSelector(state => state.user)
  const [vcard, setVcard] = useState('')
  const [qrcodeImage, setQrcodeImage] = useState(null)
  const whitelabel = useGetWhitelabel()
  const customDomain = whitelabel?.customDomain || ''
  const { t } = useTranslation()
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage()
  const [getQrCodeImageById] = useLazyGetQrCodeImageByIdQuery()
  const [loading, setLoading] = useState(true)

  const getVcardValue = useCallback(async () => {
    try {
      const response = await fetch(
        `https://europe-west3-clink-d33fc.cloudfunctions.net/createDownloadvCardTEST?uid=${currentUser.uid}&slug=${currentUser.whiteLabel}&customDomain=${customDomain}`,
      )
      const data = await response.text()
      const vcardValue = data.replace(
        /PHOTO;ENCODING=b:.*\n/g,
        `PHOTO;ENCODING=b:${currentUser.photoURL}\n`,
      )

      setLocalStorageItem(`vcard_${currentUser.uid}`, vcardValue)
      setVcard(vcardValue)
    } catch (error) {
      toast.error(error.message || t('toastErrorDefault.error'))
    }
  }, [currentUser, customDomain, t, setLocalStorageItem])

  const handleChangeMode = useCallback(() => {
    const mode = shareProfileMode === 'PROFILE' ? 'VCARD' : 'PROFILE'
    setShareProfileMode(mode)
    if (mode === 'VCARD' && !vcard) {
      setLoading(true)
    }
  }, [vcard, shareProfileMode])

  const getQrCode = useCallback(
    async id => {
      try {
        const res = await getQrCodeImageById(id)
        const qrcodePng = res?.data?.urls?.png
        if (qrcodePng) {
          const newstoredQrCode = {
            url: qrcodePng,
            attributes: currentUser.qrcode?.attributes,
          }
          setQrcodeImage(`${qrcodePng}?date=${Date.now()}`)
          setLocalStorageItem(`qrcode_${currentUser.uid}`, newstoredQrCode)
        }
      } catch (error) {
        setLoading(false)
        console.warn(error)
      }
    },
    [
      currentUser.uid,
      setQrcodeImage,
      setLocalStorageItem,
      getQrCodeImageById,
      currentUser.qrcode?.attributes,
    ],
  )

  const getInitialValues = useCallback(() => {
    const [vcardValue, qrcodeValue] = [
      getLocalStorageItem(`vcard_${currentUser.uid}`),
      getLocalStorageItem(`qrcode_${currentUser.uid}`),
    ]

    if (vcardValue && !vcard) {
      setVcard(vcardValue)
    }

    if (qrcodeValue) {
      setQrcodeImage(`${qrcodeValue.url}?date=${Date.now()}`)
    } else {
      getQrCode(currentUser.qrcode?.id)
    }

    if (!qrcodeValue) {
      setLoading(true)
    }
  }, [
    currentUser.uid,
    vcard,
    getQrCode,
    currentUser.qrcode?.id,
    getLocalStorageItem,
  ])

  const onSaveSvg = useCallback(async () => {
    if (!qrcodeRef.current) {
      return null
    }

    const canvas = qrcodeRef.current.children[0]
    const pngFile = canvas.toDataURL('image/png')

    const downloadLink = document.createElement('a')
    downloadLink.download = 'QrCode'
    downloadLink.href = `${pngFile}`
    downloadLink.click()
  }, [qrcodeRef])

  useEffect(() => {
    getVcardValue()
  }, [getVcardValue])

  useEffect(() => {
    getInitialValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (vcard && shareProfileMode === 'VCARD') {
      setLoading(false)
    }
    if (qrcodeImage && shareProfileMode === 'PROFILE') {
      setLoading(false)
    }
  }, [vcard, qrcodeImage, shareProfileMode])

  useEffect(() => {
    timer.current = setTimeout(() => {
      setLoading(false)
    }, 10000)

    return () => clearTimeout(timer.current)
  }, [])

  const options = [
    { label: t('share_profil.title'), value: 'PROFILE' },
    {
      label: t('share_profil.type_profil.share_contact_file'),
      value: 'VCARD',
    },
  ]

  return (
    <>
      <Card>
        <Select
          value={options.find(option => option.value === shareProfileMode)}
          onSelect={handleChangeMode}
          options={options}
          variant="secondary"
        />
        <div>
          <QRCode
            qrcodeRef={qrcodeRef}
            shareProfileMode={shareProfileMode}
            vcard={vcard}
            qrcodeImage={qrcodeImage}
            loading={loading}
          />
        </div>
      </Card>
      <QrcodeBloc
        disabled={!vcard && !qrcodeImage}
        config={
          shareProfileMode === 'PROFILE'
            ? {
                download: true,
                type: 'link',
                href: qrcodeImage,
              }
            : {
                onClick: onSaveSvg,
              }
        }
      />
    </>
  )
}
export default CardQrCode
