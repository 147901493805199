import React from 'react'
import { Stepper } from '../Stepper'
import Text from '../Texts/Text'
import { useGetWhitelabel, useTheme } from '@/Hooks'
import { LineMessage5 } from '@/Assets/icons/components'
import { styled } from 'styled-components'
import clsx from 'clsx'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
`

export const IconLabel = ({ childrenClassName }) => {
  const { theme } = useTheme()
  const whitelabel = useGetWhitelabel()
  return (
    <div className={clsx('flex flex-row items-center', childrenClassName)}>
      <img
        className="w-10 h-10 mr-2"
        src={whitelabel?.logoSmall}
        alt="logo-budly"
      />
      <Text color={theme.colors.primary} className="text-2xl">
        {whitelabel?.whiteLabelName || 'YourLifeCard'}
      </Text>
    </div>
  )
}
const Footer = () => {
  const whitelabel = useGetWhitelabel()
  const { theme } = useTheme()

  return (
    <div className="flex flex-col px-2 py-10 w-full">
      <div className="flex flex-row items-center shrink">
        <LineMessage5
          color={theme.colors.grey2}
          size={theme.size.xxs}
          viewBox="0 0 24 24"
        />
        <Text className="text-sm ml-1" regular color={theme.colors.grey2}>
          <a
            href={`mailto:${whitelabel?.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {whitelabel?.email}
          </a>
        </Text>
      </div>
      <Text
        className="text-sm text-ellipsis w-1/2 text-nowrap overflow-hidden"
        regular="true"
        color={theme.colors.grey2}
      >
        © {whitelabel?.whiteLabelName || 'YourLifeCard'}{' '}
        {new Date().getFullYear()}
      </Text>
    </div>
  )
}

export const Steps = () => {
  return (
    <Container className="h-full rounded-[40px] w-[392px] flex flex-col item-center justify-center px-4 pt-10">
      <IconLabel childrenClassName="mb-16 pl-6 tablet:mb-10" />
      <Stepper />
      <Footer />
    </Container>
  )
}
