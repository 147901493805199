const admin_whitelabel = {
  admin_space: 'Admin Space',
  manage_your_users: 'Find your admin space and manage your users.',
  users: 'Users',
  custom_domain: 'Custom Domain',
  custom_email: 'Custom Email',
  annexes: 'Annexes',
  your_users: 'Your Users',
  custom_email_with_smtp: 'Custom Email with SMTP',
  customize_the_domain:
    'Customize the email domain that will be used with your users with SMTP',
  custom_email_with_gmail: 'Custom Email with GMAIL',
  customize_the_gmail:
    'Customize the email domain that will be used with your users with GMAIL',
  host: 'Host',
  port: 'Port',
  user: 'User',
  pass: 'Password',
  link_signup: 'Sign Up Link',
  copy_the_link:
    'Copy the link below to invite users to register on your platform',
  legal_links: 'Legal Links',
  modify_the_links:
    'You can modify your terms and conditions and privacy policy links here',
  general_conditions: 'Terms and Conditions',
  privacy_policy: 'Privacy Policy',
  intercom_token: 'Token Intercom',
  connect_your_own_livechat:
    'You can add your intercom token to connect your own livechat',
  collaborator_details: 'Collaborator Details',
  no_username: 'No username',
  member_since: 'Member since',
}

export default admin_whitelabel
