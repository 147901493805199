const admin_whitelabel = {
  admin_space: 'Espacio de Administración',
  manage_your_users:
    'Encuentre su espacio de administrador y la gestión de sus usuarios.',
  users: 'Usuarios',
  custom_domain: 'Dominio personalizado',
  custom_email: 'Correo electrónico personalizado',
  annexes: 'Anexos',
  your_users: 'Sus usuarios',
  custom_email_with_smtp: 'Correo electrónico personalizado con SMTP',
  customize_the_domain:
    'Personalice el dominio del correo electrónico que se utilizará con sus usuarios con SMTP',
  custom_email_with_gmail: 'Correo electrónico personalizado con GMAIL',
  customize_the_gmail:
    'Personalice el dominio del correo electrónico que se utilizará con sus usuarios con GMAIL',
  host: 'Host',
  port: 'Puerto',
  user: 'Usuario',
  pass: 'Contraseña',
  link_signup: 'Enlace de registro',
  copy_the_link:
    'Copie el siguiente enlace para invitar a los usuarios a registrarse en su plataforma',
  legal_links: 'Enlaces legales',
  modify_the_links:
    'Puede modificar sus enlaces de términos y condiciones y política de privacidad aquí',
  general_conditions: 'Términos y condiciones',
  privacy_policy: 'Política de privacidad',
  intercom_token: 'Token Intercom',
  connect_your_own_livechat:
    'Puede agregar su token intercom para conectar su propio livechat',
  collaborator_details: 'Detalles del colaborador',
  no_username: 'No username',
  member_since: 'Membre depuis le',
}

export default admin_whitelabel
