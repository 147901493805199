import {
  LineMenu,
  LineMessage,
  LineStatus,
  LineTurnOff,
  LineUser,
} from '@/Assets/icons/components'
import React, { useMemo } from 'react'
import OptionBloc from '../OptionBloc'
import { useTranslation } from 'react-i18next'
import { signOutUser } from '@/Firebase/Auth'
import { useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useGetWhitelabel } from '@/Hooks'
import { useSelector } from 'react-redux'
import PanelProducts from '../Panels/PanelProducts/PanelProducts'
import { useGetFullName } from '@/Hooks/useGetFullName'

export const OptionsMenu = () => {
  const { currentUser } = useSelector(state => state.user)
  const { uid, email } = currentUser
  const name = useGetFullName(currentUser)
  const { t } = useTranslation()
  const whitelabel = useGetWhitelabel()
  const navigate = useNavigate()
  const { show } = useIntercom()

  const options = useMemo(
    () => [
      {
        title: 'parametre.title_bloc2',
        options: [
          {
            text: 'parametre.title',
            icon: LineUser,
            onClick: () => navigate('/dashboard/settings/account'),
          },
          {
            text: 'statistics.title',
            icon: LineStatus,
            onClick: () => navigate('/dashboard/statistics'),
          },
          {
            text: 'parametre.produit_ylc.my_products.insight',
            icon: LineMenu,
            onClick: setIsOpen => setIsOpen(true),
            parent: PanelProducts,
            parentProps: {
              uid,
              email,
              name,
            },
          },
          // {
          //   text: 'parametre.produit_ylc.my_products.insight',
          //   icon: LineMenu,
          //   onClick: () => console.log('Press3'),
          // },
        ],
      },
      ...(whitelabel?.livechatToken
        ? [
            {
              title: 'parametre.title_bloc4',
              options: [
                {
                  text: 'parametre.share.contact_us.title',
                  icon: LineMessage,
                  onClick: () => show(),
                },
                // {
                //   text: 'FAQ',
                //   icon: LineQuestion,
                //   onClick: () => console.log('Press5'),
                // },
              ],
            },
          ]
        : []),
      {
        options: [
          {
            text: 'parametre.share.deconnect.title',
            onClick: () => signOutUser(),
            icon: LineTurnOff,
          },
        ],
      },
    ],
    [uid, email, name, navigate, show, whitelabel],
  )

  return (
    <>
      {options.map(({ title, options }, index) => (
        <OptionBloc
          key={index}
          title={t(title)}
          options={options}
          bgDisabled
          last={options.length === index + 1}
        />
      ))}
    </>
  )
}
