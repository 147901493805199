const PORT = '192.168.1.149'
//const PORT = '192.168.1.23'
export const Config = {
  API_URL: 'https://europe-west3-clink-d33fc.cloudfunctions.net/api2',
  API_URL_DEV: `http://${PORT}:5001/clink-d33fc/europe-west3/api2`,
  ADMIN_UID: 'PJHy4SGGNnhWPijYssTpKEtkAox2',
  FILESTACK_API_KEY: 'A31MqJVcLQvuKQVlsYc0xz',
  BEACONSTAC_API_KEY: '8766bd4073b00b210f3d1ea58808b679b94258b5',
  BEACONSTAC_ORGANIZATION: '594552',
}
